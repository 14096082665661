.App {
  text-align: center;
}

.bold {
  font-weight: bold !important;
}

.sem-padding {
  padding: 0px !important;
}

.alinha-esquerda {
  text-align: left;
}

.alinha-direita {
  text-align: right;
}
